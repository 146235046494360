<template>
  <app-wrapper>
    <page-header title="Raporty"></page-header>
    <div class="container mt-3">
      <div class="row">
<!--        <div class="col-lg-4 col-sm-12">-->
<!--          <div class="card">-->
<!--            <div class="card-header">-->
<!--              Zadania zakończone danego pracownika-->
<!--            </div>-->
<!--            <div class="card-body">-->
<!--              <form class="form">-->
<!--                <div class="row">-->
<!--                  <div class="input-group mb-3">-->
<!--                    <span class="input-group-text" >Pracownik</span>-->
<!--                    <select class="form-select" v-model="user">-->
<!--                      <option v-for="user in this.$store.getters.sortedUsers" :key="user.id" :value="user.id">{{ user.name }}</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <input class="form-control" type="date" v-model="from" />-->
<!--                  </div>-->
<!--                  <div class="col">-->
<!--                    <input class="form-control" type="date" v-model="to" />-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="buttons">-->
<!--                  <a class="btn btn-primary mt-3 mb-3" type="button" v-on:click="getFileURL()" target="_blank">Wygeneruj raport</a>-->
<!--                  <a class="btn btn-success mt-3 mb-3" type="button" :href="this.signedUrl" v-if="this.signedUrl !== '' && this.signedUrl !== false" target="_blank">Pobierz raport</a>-->
<!--                </div>-->
<!--              </form>-->
<!--              <div v-if="message !== ''" class="card-footer" :class="messageIsError ? 'error-card-form' : 'success-card-form'" v-html="message"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <TimeReports />
      </div>
    </div>
  </app-wrapper>
</template>

<script>
import PageHeader from "../components/PageHeader";
import AppWrapper from "../components/AppWrapper";
import api from "../api";
import TimeReports from "@/components/TimeReports.vue";

export default {
  name: "Reports",
  components: {TimeReports, PageHeader, AppWrapper},
  data(){
    return {
      user: this.$store.getters.sortedUsers[0].id,
      from: this.getDefaultFromDate(),
      to: this.getDefaultToDate(),
      signedUrl: '',
      message: '',
      messageIsError: false,
    }
  },
  methods: {
    getDefaultFromDate(){
      let date = new Date();
      date.setDate(1);
      return date.toISOString().substring(0, 10);
    },
    getDefaultToDate(){
      let date = new Date();
      return date.toISOString().substring(0, 10);
    },
    getFileURL(){
      this.message = ''
      api.getTasksDoneReportLink(this.user, this.from, this.to)
          .then( (response) => {
            this.signedUrl = response.data
            this.message = 'Raport możliwy do pobrania'
            this.messageIsError = false
          })
          .catch( (error) => {
            this.signedUrl = false
            if (error.response.status === 404) {
              this.message = 'Żadne zadania nie pasują do wskazanego zakresu'
              this.messageIsError = true
            }
            else console.log(error)
          });
    }
  }
}
</script>

<style>
  .error-card-form{
    background-color: red;
  }

  .success-card-form{
    background-color: green;
  }

  .buttons{
    display: flex;
    justify-content: space-between;
  }
</style>
