import axios from "axios";
import store from "./store/index";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("auth_token") || null;

const API_URL = process.env.VUE_APP_API_URL;

export default {
  login(email, password) {
    return axios.post(API_URL + 'api/login', {
      email: email,
      password: password
    })
      .then(response => {
        localStorage.setItem('auth_token', response.data.token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
        return this.getLoggedInUser();
      });
  },
  getLoggedInUser() {
    return axios.get(API_URL + "api/me");
  },
  logout() {
    return axios.post(API_URL + 'api/logout')
      .then(response => {
        store.dispatch('logout');
        return response;
      });
  },
  sendResetLink(email) {
    return axios.post(API_URL + 'api/password/email', { email });
  },
  resetPassword(token, email, password, password_confirmation) {
    return axios.post(API_URL + 'api/password/reset', {
      token,
      email,
      password,
      password_confirmation
    });
  },
  getCorporateClients() {
    return axios.get(API_URL + "api/corporateclients");
  },
  createCorporateClient(name, key) {
    return axios.post(API_URL + "api/corporateclients", {
      name,
      key,
    });
  },
  updateCorporateClient(id, name, key) {
    return axios.patch(API_URL + "api/corporateclients/" + id, {
      name,
      key,
    });
  },
  deleteCorporateClient(id) {
    return axios.delete(API_URL + "api/corporateclients/" + id);
  },
  getUsers() {
    return axios.get(API_URL + "api/users");
  },
  createUser(data) {
    return axios.post(API_URL + "api/users", data);
  },
  updateUser(data) {
    return axios.put(API_URL + "api/users", data);
  },
  deleteUser(id) {
    return axios.delete(API_URL + "api/users/" + id);
  },
  getArchived(data, pageNumber) {
    return axios.post(API_URL + "api/tasks/archived?page=" + pageNumber, data);
  },
  getTasks() {
    return axios.get(API_URL + "api/tasks");
  },
  getTask(id) {
    return axios.get(API_URL + "api/tasks/" + id);
  },
  getFiles(id) {
    return axios.get(API_URL + "api/tasks/" + id + "/files");
  },
  getArchivedFiles(id) {
    return axios.get(API_URL + "api/tasks/" + id + "/archived/files");
  },
  getFilesByPath(path) {
    return axios.post(API_URL + "api/tasks/files", { path });
  },
  getShareLink(path) {
    return axios.post(API_URL + "api/tasks/files/link", { path });
  },
  updateTask(id, data) {
    return axios.patch(API_URL + "api/tasks/" + id, data);
  },
  restoreTask(uuid) {
    return axios.patch(API_URL + "api/tasks/" + uuid + "/reopen");
  },
  approveTask(id) {
    return axios.patch(API_URL + "api/tasks/" + id + "/approve");
  },
  createTask(data) {
    return axios.post(API_URL + "api/tasks", data);
  },
  uploadAttachments(id, files, comments, type) {
    const formData = new FormData();
    formData.append("type", type);
    files.forEach((f) => formData.append("attachments[]", f));
    comments.forEach((s) => formData.append("comments[]", s));
    return axios.post(API_URL + `api/tasks/${id}/attachments`, formData);
  },
  downloadAttachment(id, uuid) {
    return axios.get(API_URL + `api/tasks/${id}/attachments/${uuid}`);
  },
  setStatusQa(task_id) {
    return axios.patch(API_URL + "api/tasks/" + task_id + "/set-status/qa");
  },
  updateUserStatus(id, name) {
    return axios.patch(API_URL + `api/users/${id}/set-status`, {
      status: name,
    });
  },
  reorderTasks(ordering) {
    return axios.post(API_URL + "api/tasks/reorder", { ordering: ordering });
  },
  getTasksDoneReportLink(user, from, to) {
    return axios.get(
      API_URL + `api/reports/export/tasksDone/${user}/${from}/${to}`
    );
  },
  getTimeReports(from, to) {
    return axios.post(API_URL + `api/reports/time`, {
        from: from,
        to: to,
      }
    );
  },
  startTask(task_id, user_id) {
    return axios.post(API_URL + `api/tasks/${task_id}/start`, {
      employee_id: user_id
    });
  },
  stopTask(task_id, user_id) {
    return axios.patch(API_URL + `api/tasks/${task_id}/pause`, {
      employee_id: user_id
    });
  },
  sendNotification(id) {
    return axios.post(API_URL + "api/tasks/" + id + "/notify");
  },
  updateWorkLog(logId, payload) {
    return axios.patch(API_URL + `api/work-logs/${logId}`, payload)
  }
};
