import { createStore } from 'vuex'
import snack from './snack'
import api from "@/api";
import axios from "axios";

const getInactive = (tasks) => {
  return tasks.filter((t) => t.status === 'qa' || t.status === 'corporate_client_qa').sort((a,b) => {
    if(a.status === 'qa' && b.status === 'corporate_client_qa') {
      return -1;
    } else if (b.status === 'qa' && a.status === 'corporate_client_qa') {
      return 1;
    } else {
      return 0;
    }
  })
}

const getOrdered = (tasks) => {
  return tasks.filter((t) => t.status !== 'qa' && t.status !== 'corporate_client_qa').sort((a,b) => {
    if (a.order === null && b.order !== null) {
      return 1
    } else if (a.order !== null && b.order === null) {
      return -1
    } else {
      return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)
    }
  })
}

export default createStore({
  state: {
    loading: false,
    user: null,
    users: [],
    tasks: [],
    corporateClients: [],
    filters: {
      users: [],
      groups: [],
      accounts: [],
      clientId: null,
      unapproved: false,
      unassigned: false,
      excludeEmpty: true,
    },
  },
  getters: {
    loading: (state) => {
      return state.loading;
    },
    getTasks: (state) => {
      return state.tasks;
    },
    getTaskByUuid: (state, getters) => (uuid, userId) => {
      const result = getters.userTasks(userId).filter(t => t.uuid === uuid)
      return result.length > 0 ? result[0] : undefined;
    },
    filteredUsers: (state, getters) => {
      let users = state.users.sort((user1, user2) => {
        return user1.name > user2.name ? 1 : -1
      });
      if(state.filters.excludeEmpty) {
        users =  users.filter(u => {
          if(getters.userTasks(u.id).length > 0) return true
        })
      }
      if(state.filters.unassigned) {
        users = users.filter(u => u.name === "zadania nieprzypisane");
      }
      if(state.filters.users.length === 0) return users
      users = users.filter(u => {
        if(state.filters.users.includes(u.name)) return true;
      })
      return users
    },
    sortedUsers: state => {
      return state.users.sort((user1, user2) => {
        return user1.name > user2.name ? 1 : -1
      })
    },
    sortedClients: state => {
      return state.corporateClients.sort((client1, client2) => {
        return client1.name.toLowerCase() > client2.name.toLowerCase() ? 1 : -1
      })
    },
    accountUsers: state => {
      return state.users.filter(u => u.groups.find(g => g.name === 'account') && u.name !== "zadania nieprzypisane")
    },
    graphicsUSers(state) {
      return state.users.filter(u => u.groups.find(g => g.name === 'graphic_creative' || 'graphic_dtp' || 'graphic_ui'))
    },
    userTasks: (state, getters) => user_id => {
      let tasks = state.tasks
          .filter(t => t.employee_id === user_id)
          .filter(t => t.status !== 'done')
          .filter(t => {
            if(state.filters.accounts.length === 0) return true
            else if( t.account && state.filters.accounts.includes(t.account.name)) return true;
          });
      if(state.filters.unapproved) tasks =  tasks.filter(t => t.unapproved)
      if(state.filters.clientId) tasks =  tasks.filter(t => t.corporate_client_id === state.filters.clientId)
      if(!getters.isAdmin && !getters.isAccount) tasks = tasks.filter(t => !t.unapproved)
      const orderedTasks = getOrdered(tasks);
      const inactiveTasks = getInactive(tasks);

      orderedTasks.sort((a, b) => {
        const hasDeadlineA = !!a.deadline;
        const hasDeadlineB = !!b.deadline;

        if (hasDeadlineA && hasDeadlineB) {
          const dateA = new Date(a.deadline.replace(' ', 'T'));
          const dateB = new Date(b.deadline.replace(' ', 'T'));
          return dateA - dateB;
        } else if (hasDeadlineA && !hasDeadlineB) {
          return -1;
        } else if (!hasDeadlineA && hasDeadlineB) {
          return 1;
        } else {
          return 0;
        }
      });

      inactiveTasks.sort((a, b) => {
        const hasDeadlineA = !!a.deadline;
        const hasDeadlineB = !!b.deadline;

        if (hasDeadlineA && hasDeadlineB) {
          const dateA = new Date(a.deadline.replace(' ', 'T'));
          const dateB = new Date(b.deadline.replace(' ', 'T'));
          return dateA - dateB;
        } else if (hasDeadlineA && !hasDeadlineB) {
          return -1;
        } else if (!hasDeadlineA && hasDeadlineB) {
          return 1;
        } else {
          return 0;
        }
      });
      return [...orderedTasks, ...inactiveTasks];
    },
    userNames: state => {
      let userNames = [];
      if(state.users){
        state.users.map(u => { userNames.push(u.name) })
      }
      return userNames.sort();
    },
    accountNames: (state, getters) => {
      let accountNames = [];
      if(getters.accountUsers){
        getters.accountUsers.map(u => { accountNames.push(u.name) })
      }
      return accountNames.sort();
    },
    groupNames: state => {
      let groupNames = [];
      state.users.map(u => {
        u.groups.map(g => { groupNames.push(g.name) })
      })
      return [...new Set(groupNames)];
    },
    groups: state => {
      const groups = state.users.flatMap(u => u.groups);
      return [...new Map(groups.map(item =>
          [item['name'], item])).values()];
    },
    isAdmin: state => {
      if(state.user){
        return state.user.type === 'admin'
      }
      else return false
    },
    isAccount: state => {
      if(state.user){
        return state.user.groups.find(g => g.name === 'account')
      }
      else return false
    },
    isUser: state => {
      if(state.user){
        return state.user.type === 'user' && !state.user.groups.find(g => g.name === 'account')
      }
      else return false
    },
  },
  mutations: {
    SET_TASKS(state, tasks){
      state.tasks = tasks
    },
    LOADING(state, enable) {
      state.loading = enable
    },
    USER(state, user) {
      state.user = user
      if(user) localStorage.setItem('user', user  )
      else localStorage.removeItem('user')
    },
    CLEAR_USER(state) {
      state.user = null;
      localStorage.removeItem('user')
    },
    LOGOUT(state) {
      state.user = null;
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['Authorization'];
    },
    FILTERED_USERS(state, filteredUsers) {
      state.filteredUsers = filteredUsers
    },
    ADD_USERS_TO_FILTER_FROM_GROUP(state, group) {
      let users = [...state.filters.users]
      state.users.map(u => {
        if(u.groups.find(g => g.name === group)) users.push(u.name)
      })
      state.filters.users = [...new Set(users)]
    },
    REMOVE_USERS_FROM_FILTER_FROM_GROUP(state, group) {
      let users = [...state.filters.users]
      state.users.map(u => {
        if(u.groups.find(g => g.name === group)) users.splice(users.indexOf(u.name), 1)
      })
      state.filters.users = [...new Set(users)]
    },
    ADD_TASK(state, task) {
      let newTasks = [...state.tasks]
      newTasks.push(task)
      state.tasks = newTasks
    },
    TASK_UPDATED(state, task) {
      let newTasks = [...state.tasks]
      let oldTask = newTasks.find(t => t.uuid === task.uuid)
      newTasks.splice(newTasks.indexOf(oldTask), 1)
      newTasks.push(task)
      state.tasks = newTasks
    },
    TASK_UPDATED_WORK_LOG(state, updatedTasks) {
      let tasksArray = Array.isArray(updatedTasks) ? updatedTasks : [updatedTasks];
      let newTasks = [...state.tasks];
      tasksArray.forEach(task => {
        let oldTask = newTasks.find(t => t.uuid === task.uuid);
        if (oldTask) {
          oldTask.in_work = task.in_work;
          oldTask.work_logs = task.work_logs;
        } else {
          newTasks.push(task);
        }
      });
      state.tasks = newTasks
    },
    ADD_CORPORATE_CLIENT(state, corporateClient) {
      let newCc = [...state.corporateClients]
      newCc.push(corporateClient)
      state.corporateClients = newCc
    },
    UPDATE_CORPORATE_CLIENT(state, corporateClient) {
      let updatedCc = [...state.corporateClients]
      const updateClientIndex = state.corporateClients.findIndex(c => c.id === corporateClient.id)
      updatedCc[updateClientIndex] = corporateClient
      state.corporateClients = updatedCc
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_CORPORATE_CLIENTS(state, clients) {
      state.corporateClients = clients;
    }
  },
  actions: {
    downloadTasks({ commit }){
      api.getTasks()
          .then(response => {
            commit('SET_TASKS', response.data)
          })
    },
    setUser({ commit }, user) {
      commit('USER', user)
    },
    setFilteredUsers({ commit }, filteredUsers) {
      commit('FILTERED_USERS', filteredUsers)
    },
    fetchUsers({ commit }) {
      return api.getUsers()
        .then(response => {
          commit('SET_USERS', response.data);
        });
    },
    fetchCorporateClients({ commit }) {
      return api.getCorporateClients()
        .then(response => {
          commit('SET_CORPORATE_CLIENTS', response.data);
        });
    },
    clearUser({ commit }) {
      commit('CLEAR_USER');
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    loading({ commit }, status) {
      commit('LOADING', status)
    }
  },
  modules: {
    snack,
  }
})
