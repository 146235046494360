<template>
  <div v-if="user" class="my-5">
    <h2>{{ user.name }}</h2>
    <user-status-edit :status="user.status" :user_id="user.id" />
    <table class="table table-striped gy-4 gs-4 mt-4 mb-0">
      <thead>
        <tr>
          <th style="width: 1%; white-space: nowrap"></th>
<!--          <th-->
<!--            v-if="$store.state.user.type === 'admin'"-->
<!--            style="width: 1%; white-space: nowrap"-->
<!--          ></th>-->
          <th
            v-if="$store.state.user.type === 'admin'"
            style="width: 1%; white-space: nowrap"
          ></th>
          <th style="width: 90px"></th>
          <th style="width: 12%">
            <i class="icon-Office"></i>&nbsp;&nbsp;klient
          </th>
          <th style="width: 12%"><i class="" />nr zadania</th>
          <th><i class="icon-Paragraph"></i>&nbsp;&nbsp;temat</th>
          <th v-if="$store.getters.isAdmin"><i class="icon-Timeline" />&nbsp;czas</th>
          <th v-if="$store.getters.isAdmin" style="min-width: 170px;"><i class="icon-Clock" />&nbsp;deadline</th>
          <th style="width: 12%">
            <i class="icon-Person"></i>&nbsp;&nbsp;account
          </th>
          <th style="width: 150px">
            <i class="icon-Flag"></i>&nbsp;&nbsp;status
          </th>
          <th style="width: 1%; white-space: nowrap"></th>
          <th style="width: 1%; white-space: nowrap"></th>
          <th style="width: 1%; white-space: nowrap"></th>
          <th style="width: 1%; white-space: nowrap"></th>
        </tr>
      </thead>
      <tbody :id="'dragula-container_' + user.id">
        <task-row
          v-for="t in $store.getters.userTasks(user.id)"
          :key="t.id"
          :task="t"
          @openTask="openTask(t)"
        />
      </tbody>

    </table>
    <task-dialog
        v-if="isTaskOpened"
      :employee_id="user.id"
      :task="editedTask"
      :read-only="!editMode"
      :is-dialog-open="isTaskOpened"
      @created="handleTaskCreated"
      @close="handleClose"
    />
    <button v-if="!isTaskOpened" class="nt_btn" @click="openNewTask">
      <i class="icon-Plus mx-3" />Dodaj nowy wiersz
    </button>
  </div>
</template>

<script>
import TaskRow from "@/components/TaskRow";
import UserStatusEdit from "@/components/UserStatusEdit";
import dragula from "dragula";
import api from "../api";
import TaskDialog from "@/components/TaskDialog";

export default {
  name: "UserTasksEdit",
  components: { TaskDialog, TaskRow, UserStatusEdit },
  props: {
    taskId: String,
    initUser: Object,
  },
  data() {
    return {
      isTaskOpened: false,
      editMode: false,
      user: null,
      editedTask: null,
    };
  },
  mounted() {
    this.user = this.initUser;
    // if (this.$store.state.user.type === "admin") {
    //   setTimeout(this.initDragula, 500);
    // }
    const taskUuid = this.$route.query.taskId;
    if(taskUuid) {
      const task = this.$store.getters.getTaskByUuid(taskUuid, this.user.id)
      if(task) {
        this.openTaskFromUrl(task)
      }
    }
  },
  methods: {
    openNewTask(){
      this.editMode = true;
      this.isTaskOpened = true;
    },
    openTaskFromUrl(task) {
      if (task.uuid === this.taskId && !this.isTaskOpened) {
        this.openTask(task);
      }
    },
    handleTaskCreated(task) {
      this.editMode = false;
      this.$store.commit("ADD_TASK", task);
    },
    handleClose() {
      this.isTaskOpened = false;
      this.editMode = false;
      this.editedTask = null;
    },
    openTask(task) {
      this.editedTask = task;
      this.editMode = (this.$store.getters.isAdmin ||
          this.$store.state.user.id === task.account_id)
      this.isTaskOpened = true;
    },
    initDragula() {
      let drake = dragula({
        containers: [
          document.getElementById("dragula-container_" + this.user.id),
        ],
      });
      drake.on("drop", (el, target, src, sibling) => {
        src, sibling;
        let task_ordering = [];
        target.childNodes.forEach((taskNode) => {
          if (taskNode.dataset) {
            task_ordering.push(taskNode.dataset.task_id);
          }
        });
        api.reorderTasks(task_ordering);
        // TUTAJ WYSYLAC DO API INFO Z KOLEJNOSCIA
        return true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.nt_btn {
  text-align: center;
  color: $secondary;
  border: 1px dashed #d3dde8;
  background: #f8fafe;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
  padding: 13px;
  &:hover {
    background: darken(#f8fafe, 10%);
  }
}
h2 {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
}
thead {
  border-style: hidden;
  color: $primary;
  background: #dbe3fb;
}
</style>
