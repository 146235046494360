<template>
  <div class="my-5">
    <h2>{{ user.name }}</h2>
    <user-status :status="user.status" class="mt-3" />
    <table class="table table-striped gy-4 gs-4 mt-5">
      <thead>
        <tr>
          <th style="width: 1%; white-space: nowrap"></th>
          <th
            v-if="$store.getters.isAdmin || $store.getters.isAccount"
            style="width: 1%; white-space: nowrap"
          ></th>
          <th style="width: 12%"><i class="icon-Office" />klient</th>
          <th style="width: 12%"><i class="icon-List1" />nr zadania</th>
          <th><i class="icon-Paragraph" />temat</th>
          <th style="min-width: 170px;"><i class="icon-Clock" />deadline</th>
          <th style="width: 12%"><i class="icon-Person" />account</th>
          <th style="width: 150px"><i class="icon-Flag" />status</th>
          <th style="width: 1%; white-space: nowrap"></th>
          <th style="width: 1%; white-space: nowrap"></th>
          <th style="width: 1%; white-space: nowrap"></th>
        </tr>
      </thead>
      <tbody>
        <user-task
          v-for="t in $store.getters.userTasks(user.id)"
          :key="t.id"
          :task="t"
          @openDialog="() => handleClick(t)"
        ></user-task>
        <task-dialog
          v-if="openDialog"
          :task="selectedTask"
          :read-only="true"
          :is-dialog-open="openDialog"
          @close="handleClose"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import UserTask from "@/components/UserTask";
import UserStatus from "@/components/UserStatus";
import TaskDialog from "@/components/TaskDialog";

export default {
  name: "UserTasks",
  data() {
    return {
      isTaskOpened: false,
      openDialog: false,
      selectedTask: null,
    };
  },
  components: {
    UserTask,
    UserStatus,
    TaskDialog,
  },
  props: {
    user: Object,
    taskId: String,
  },
  mounted() {
    const taskUuid = this.$route.query.taskId;
    if(taskUuid) {
      const task = this.$store.getters.getTaskByUuid(taskUuid, this.user.id)
      if(task) {
        this.openTask(task)
      }
    }
  },
  methods: {
    openTask(task) {
      if (task.uuid === this.taskId && !this.isTaskOpened) {
        this.handleClick(task);
        this.isTaskOpened = true;
      }
    },
    handleClick(task) {
      this.selectedTask = task;
      this.openDialog = true;
    },
    handleClose() {
      this.openDialog = false;
      this.task = null;
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
}
thead {
  border-style: hidden;
  color: $primary;
  background: #dbe3fb;
  i {
    margin-right: 6px;
  }
}
th,
td {
  padding-left: 20px;
}
th {
  font-size: 11px;
  font-weight: 500;
}
</style>
