<template>
  <tr
    @mouseover="handleHover"
    @mouseleave="
      qaActive = false;
      inWorkActive = false;
    "
    :class="{
      disabled_row:
        task.status === 'qa' || task.status === 'corporate_client_qa',
    }"
  >
    <td class="btn_container">
      <div
        v-if="$store.state.user && task.employee_id === $store.state.user.id && task.unapproved === 0"
        class="in_progress_btn active"
      >
        <button v-if="task.in_work === true" @click="stopTask">
          <i class="icon-Stop" />
        </button>
        <button v-else @click="startTask"><i class="icon-Play" /></button>
      </div>
    </td>
    <td v-if="$store.getters.isAdmin || $store.getters.isAccount">
      <span v-if="task.unapproved" class="unapproved-label"
        >niezaakceptowane</span
      >
    </td>
    <td @click="openDialog">{{ task.corporate_client ? task.corporate_client.name : '' }}</td>
    <td @click="openDialog" v-if="task.ref_num !== null">
      {{ task.created_at.substring(2, 4) + "_" + task.ref_num }}
    </td>
    <td @click="openDialog" v-else>-</td>
    <td @click="openDialog">{{ task.topic }}</td>
    <td @click="openDialog">
      <div>
        <span v-if="task.deadline" class="d-inline-block">
          {{ task.deadline || ' ' }}
        </span>
        <span v-else></span>
      </div>
    </td>
    <td @click="openDialog">{{ task.account ? task.account.name : "" }}</td>
    <td @click="openDialog">
      <task-status-label :status="task.status" gif :error="isQAOverdue(task)" />
      <task-status-label
        v-if="task.in_work === true"
        status="in_work"
        gif
      />
      <task-status-label
        v-else-if="task.work_logs.length > 0"
        status="pause"
        gif
      />
    </td>
    <td @click="openDialog">
      <span
        v-if="task.description"
        class="d-inline-block"
        data-bs-toggle="popover"
        data-bs-trigger="hover"
        :data-bs-content="task.description"
        ref="comment"
        ><i class="icon-Comment"></i
      ></span>
    </td>
    <td @click="openDialog">
      <span
        v-if="task.deadline"
        class="d-inline-block"
        data-bs-toggle="popover"
        data-bs-trigger="hover"
        :data-bs-content="task.deadline"
        ref="deadline"
      >
        <i class="icon-Calendar" :class="[{ 'text-warning': checkDeadline }, {  'text-danger': lateness }, ]"></i>
      </span>
    </td>
    <td class="btn_container">
      <div
        v-if="
          $store.state.user &&
          task.status !== 'qa' &&
          task.employee_id === $store.state.user.id
        "
        :class="{ active: qaActive || true }"
        class="qa_btn"
      >
        <button @click="setStatusQa"><i class="icon-Check" /></button>
      </div>
    </td>
  </tr>
</template>

<script>
import TaskStatusLabel from "@/components/TaskStatusLabel";
import api from "../api";
const bootstrap = require("bootstrap");

export default {
  name: "UserTask",
  components: {
    TaskStatusLabel,
  },
  props: {
    task: Object,
  },
  data() {
    return {
      qaActive: true,
      inWorkActive: true,
    };
  },
  mounted() {
    if (this.$refs.comment) {
      new bootstrap.Popover(this.$refs.comment, {});
    }
    if (this.$refs.deadline) {
      new bootstrap.Popover(this.$refs.deadline, {});
    }
  },
  computed: {
    lateness: function () {
      if (this.task.deadline)
        return new Date(this.task.deadline.replace(" ","T")) - Date.now() < 0;
      else return false;
    },
    checkDeadline: function () {
      if(!this.task.deadline) {
        return false;
      }
      let diff = (new Date(this.task.deadline.replace(" ","T")) - Date.now())/ 3600000;
      if (this.task.deadline)
        return diff <= 12 && diff > 0;
      else return false;
    },
  },
  methods: {
    startTask() {
      api.startTask(this.task.uuid, this.$store.state.user.id).then((response) => {
        this.$store.commit("TASK_UPDATED_WORK_LOG", response.data);
      });
    },
    openDialog() {
      this.$emit("openDialog");
    },
    stopTask() {
      api.stopTask(this.task.uuid, this.$store.state.user.id).then((response) => {
        this.$store.commit("TASK_UPDATED_WORK_LOG", response.data);
      });
    },
    setStatusQa() {
      api.setStatusQa(this.task.uuid).then((response) => {
        this.$store.commit("TASK_UPDATED", response.data);
      });
    },
    canQa() {
      if (
        this.$store.state.user &&
        (this.$store.state.user.type === "admin" ||
          (this.$store.state.user.id === this.task.employee_id &&
            (!this.task.status || this.task.status === "required_more_data")))
      )
        return true;
      return false;
    },
    handleHover() {
      if (this.canQa()) {
        this.qaActive = true;
      }
      this.inWorkActive = true;
    },
    isQAOverdue(task) {
      if (
        this.$store.state.user.id === task.account_id ||
        this.$store.state.user.type === "admin"
      ) {
        let overdueDate = new Date(task.updated_at);
        overdueDate.setDate(overdueDate.getDate() + 1);
        overdueDate = overdueDate.getTime();
        return Date.now() > overdueDate;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.unapproved-label {
  background-color: $warning;
  color: $black;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 4px;
}

.btn_container {
  padding: 0 !important;
  position: relative;
  .qa_btn {
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
  }
  .in_progress_btn {
    display: none !important;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
  }
}
tr:hover {
  .btn_container {
    .in_progress_btn {
      display: flex !important;
    }
  }
}
.icon-Calendar,
.icon-Comment {
  color: $secondary;
}
.qa_btn {
  display: none;
  button {
    width: 0;
  }
  &.active {
    display: flex;
    position: absolute;
    align-items: center;
    padding: 0;
    button {
      transition: width ease-out 200ms;
      border: none;
      height: 100%;
      background-color: #c8c9cc;
      padding: 12px 8px;
      width: 45px;
      border-radius: 0 5px 5px 0;
      &:hover {
        background-color: $success;
        width: 65px;
      }
      i {
        color: $white;
      }
    }
  }
}
.in_progress_btn {
  display: none;
  button {
    width: 0;
  }
  &.active {
    display: flex;
    position: absolute;
    align-items: center;
    padding: 0;
    button {
      transition: width ease-out 200ms;
      border: none;
      height: 100%;
      background-color: #c8c9cc;
      padding: 12px 8px;
      width: 45px;
      border-radius: 5px 0 0 5px;
      &:hover {
        background-color: $secondary;
      }
      i {
        color: $white;
      }
    }
  }
}
tr {
  position: relative;
  cursor: pointer;
  td {
    &:nth-child(1),
    &:nth-child(3) {
      font-weight: 600;
    }
  }
}
i {
  font-size: 16px;
  color: #c8c9cc;
  &.active {
    color: #4d71ec;
  }
}
.table-striped > tbody > .disabled_row:hover td{
  color: #212529 !important;
}
</style>
